import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import GitHub from '../assets/github.png';
import Tailwind from '../assets/tailwind.png';
import Python from '../assets/python.png';
import Figma from '../assets/figma.png';
import Swift from '../assets/swift.png';
import Xcode from '../assets/xcode.png';
import Vscode from '../assets/vscode.png';
import Adobe from '../assets/adobe.png';

const skillsData = [
  { img: HTML, name: 'HTML' },
  { img: CSS, name: 'CSS' },
  { img: JavaScript, name: 'JavaScript' },
  { img: ReactImg, name: 'React JS' },
  { img: GitHub, name: 'GitHub' },
  { img: Tailwind, name: 'Tailwind' },
  { img: Python, name: 'Python' },
  { img: Figma, name: 'Figma' },
  { img: Vscode, name: 'Visual Studio Code' },
  { img: Xcode, name: 'Xcode' },
  { img: Swift, name: 'Swift' },
  { img: Adobe, name: 'Adobe Creative Cloud' },
];

const Skills = () => {
  return (
    <div name="skills" className="w-full bg-[#343740] text-gray-300 py-16 sm:py-24">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        
        {/* Title */}
        <div>
          <p className="text-3xl sm:text-4xl font-rounded inline text-[#BF7973]">
            <span className="text-[#66D9C4]">&lt;</span>
            <span className="text-[#BF7973]">Skills </span>
            <span className="text-[#66D9C4]">/&gt;</span>
          </p>
          <p className="py-4">// These are the technologies I've worked with</p>
        </div>

        {/* Skills Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          {skillsData.map((skill, index) => (
            <SkillCard key={index} img={skill.img} name={skill.name} />
          ))}
        </div>
      </div>
    </div>
  );
};

// Reusable SkillCard Component
const SkillCard = ({ img, name }) => (
  <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 rounded-md flex flex-col items-center p-4">
    <img className="w-20 mx-auto" src={img} alt={`${name} icon`} />
    <p className="mt-4 font-rounded">{name}</p>
  </div>
);

export default Skills;