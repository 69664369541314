import React, { useState } from 'react';
import { FaBars, FaTimes, FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';
import { ReactComponent as MiniLogo } from '../assets/Logo.svg';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#343740] text-[#66D9C4] z-20">
      {/* Logo */}
      <MiniLogo className="h-12 md:h-14" />

      {/* Desktop Menu */}
      <NavMenu isMobile={false} />

      {/* Hamburger Icon */}
      <div onClick={handleClick} className="md:hidden z-10 cursor-pointer">
        {!nav ? <FaBars size={30} /> : <FaTimes size={30} />}
      </div>

      {/* Mobile Menu */}
      {nav && <NavMenu isMobile={true} handleClick={handleClick} />}

      {/* Social Icons */}
      <SocialIcons />
    </div>
  );
};

// NavMenu Component
const NavMenu = ({ isMobile, handleClick }) => {
  const menuClasses = isMobile
    ? 'absolute top-0 left-0 w-full h-screen bg-[#343740] flex flex-col justify-center items-center font-rounded'
    : 'hidden md:flex font-rounded';

  const linkClasses = isMobile ? 'py-6 text-4xl' : 'mx-4';

  return (
    <ul className={menuClasses}>
      {['home', 'skills', 'work', 'about'].map((section) => (
        <li key={section} className={linkClasses}>
          <Link to={section} smooth={true} duration={500} onClick={isMobile ? handleClick : null}>
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </Link>
        </li>
      ))}
    </ul>
  );
};

// SocialIcons Component
const SocialIcons = () => (
  <div className="hidden xl:flex fixed flex-col top-[35%] left-0">
    <ul>
      {[
        { href: 'https://www.linkedin.com/in/aaronmshannon/', icon: <FaLinkedin size={40} />, label: 'LinkedIn' },
        { href: 'https://github.com/aashannon', icon: <FaGithub size={40} />, label: 'GitHub' },
        { href: 'https://x.com/aaronmshannon', icon: <FaTwitter size={40} />, label: 'Twitter' },
        { href: 'mailto:ayren.shannon@me.com?subject=Saw%20Your%20Website', icon: <HiOutlineMail size={40} />, label: 'Email' },
        { href: '/ShannonAaronDownloadResume.pdf', icon: <BsFillPersonLinesFill size={40} />, label: 'Resume', download: 'Aaron_Shannon_Resume' },
      ].map(({ href, icon, label, download }) => (
        <li key={label} className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300">
          <a
            className="flex justify-between items-center w-full text-[#66D9C4] font-rounded"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            download={download}
          >
            {label} {icon}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Navbar;
