import React, { useState } from 'react';
import WorkImg from '../assets/phonicBloom.png';
import CampAppImg from '../assets/campingapp.png';
import SearchEngineImg from '../assets/searchEngine.png';

// Additional images for the expandable content
import PhonicBloomImg1 from '../assets/phonic-bloom-wireframe.png';
import PhonicBloomImg2 from '../assets/phonicBloom-prototype.png';
import CampAppImg1 from '../assets/campingApp-Braintstorm.png';
import CampAppImg2 from '../assets/campingApp-Flowchart.png';
import SearchEngineImg1 from '../assets/searchEngine-concept.png';
import SearchEngineImg2 from '../assets/searchEngine-UI.png';

const Work = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleExpandToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Toggle the expanded card
  };

  return (
    <div name="work" className="w-full bg-[#343740] text-gray-300 py-16 sm:py-24">
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        
        {/* Title */}
        <div className="pb-8">
          <p className="text-3xl sm:text-4xl font-rounded inline text-[#BF7973]">
            <span className="text-[#66D9C4]">&lt;</span>
            <span className="text-[#BF7973]">Work </span>
            <span className="text-[#66D9C4]">/&gt;</span>
          </p>
          <p className="py-4 text-lg">// Check out some of my recent work</p>
        </div>

        {/* Work Items Container */}
        <div className="grid grid-cols-1 gap-8">
          
          {/* Project Item */}
          <ProjectCard 
            index={0}
            isExpanded={expandedIndex === 0}
            handleExpandToggle={handleExpandToggle}
            img={WorkImg}
            title="Phonic Bloom Website"
            designRole="UI/UX Technical Designer"
            devRole="Developer"
            devRoleLink="https://dtc-wsuv.org/projects/phonic-bloom/" // Developer link for this project
            description={
              <>
                <p>
                  As the dev team lead for Phonic Bloom, I collaborated with designers and project managers to create an intuitive, user-centered interface for the prototype website and app.
                </p>
                <ul style={{ marginTop: '1em' }}>
                  <li><b>User-Centered Design:</b> Integrated feedback from usability testing into the design process.</li>
                  <li><b>Consistency and Accessibility:</b> Established a cohesive design language adhering to WCAG standards.</li>
                  <li><b>Prototyping and Wireframing:</b> Created wireframes and prototypes using Figma.</li>
                  <li><b>Responsive Design:</b> Delivered a responsive UI for seamless experiences across devices.</li>
                  <li><b>Collaborative Workflow:</b> Translated design specifications into actionable development tasks while aligning technical execution with design goals.</li>
                </ul>
                <p style={{ marginTop: '1em' }}>
                  This project honed my ability to blend design principles with technical development to create engaging, functional digital products.
                </p>
              </>
            }
            images={[PhonicBloomImg1, PhonicBloomImg2]}
          />
          
          {/* Project Item 2 */}
          <ProjectCard 
            index={1}
            isExpanded={expandedIndex === 1}
            handleExpandToggle={handleExpandToggle}
            img={CampAppImg}
            title="Camping Companion App"
            designRole="UI/UX Technical Designer"
            devRole="Developer"
            devRoleLink="https://github.com/aashannon/Camping-App" // Developer link for this project
            description={
              <>
                <p>
                  I spearheaded the development of a camping app, managing the entire lifecycle from concept to deployment.
                </p>
                <ul style={{ marginTop: '1em' }}>
                  <li><b>Flowchart Creation:</b> Designed the user journey and app functionality flowchart to guide development.</li>
                  <li><b>Concept Development:</b> Developed the app's core idea, focusing on user needs for camping-related features.</li>
                  <li><b>App Design:</b> Crafted the app’s UI/UX, ensuring an intuitive and engaging user experience.</li>
                  <li><b>Programming:</b> Built the app using Swift, implementing features like campsite search, navigation, and user interactions.</li>
                </ul>
                <p style={{ marginTop: '1em' }}>
                  This project allowed me to combine creative design with technical development to deliver a practical and user-friendly app.
                </p>
              </>
            }
            images={[CampAppImg1, CampAppImg2]}
          />
          
          {/* Project Item 3 */}
          <ProjectCard 
            index={2}
            isExpanded={expandedIndex === 2}
            handleExpandToggle={handleExpandToggle}
            img={SearchEngineImg}
            title="Full Stack Search Engine"
            designRole="UI/UX Technical Designer"
            devRole="Developer"
            devRoleLink="https://github.com/aashannon/search_engine_final" // Developer link for this project
            description={
              <>
                <p>
                  I developed a custom search engine from scratch, combining algorithm design with practical implementation to deliver accurate and efficient search results.
                </p>
                <ul style={{ marginTop: '1em' }}>
                  <li><b>Web Crawler:</b> Built a Python-based crawler to index over 100,000 pages, storing data in an adjacency matrix and extracting meaningful content.</li>
                  <li><b>Ranking Models:</b> Implemented TF-IDF and BM25 ranking models without external libraries, comparing their effectiveness in a detailed analysis.</li>
                  <li><b>Data Tagging:</b> Designed a system to tag data, enabling precise searches by state, energy type, and other filters.</li>
                  <li><b>SQL Challenges:</b> Overcame SQLite variable limits and optimized queries with SQLAlchemy and JSON for seamless data storage.</li>
                  <li><b>PageRank Algorithm:</b> Developed a command-line tool to rank search results using the PageRank algorithm, analyzing convergence and performance.</li>
                </ul>
                <p style={{ marginTop: '1em' }}>
                  These projects strengthened my skills in backend development, algorithm design, and creating user-centric search functionalities.
                </p>
              </>
            }
            images={[SearchEngineImg1, SearchEngineImg2]}
          />
        </div>
      </div>
    </div>
  );
};

// Reusable ProjectCard Component with Hover and Expandable Content
const ProjectCard = ({ 
  index, 
  isExpanded, 
  handleExpandToggle, 
  img, 
  title, 
  designRole, 
  devRole, 
  devRoleLink, // Added devRoleLink prop
  description, 
  images 
}) => (
  <div className="shadow-lg shadow-[#040c16] rounded-md overflow-hidden transition-transform duration-300 transform">
    {/* Main Content with Background Image */}
    <div 
      style={{ backgroundImage: `url(${img})` }} 
      className="relative bg-cover bg-center h-64 sm:h-80 md:h-96 lg:h-[500px] flex items-center justify-center group"
    >
      {/* Overlay on Hover */}
      <div className="absolute inset-0 bg-black bg-opacity-75 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col justify-center items-center p-4">
        <span className="text-2xl font-rounded text-white tracking-wider text-center">
          {title}
        </span>
        <div className="pt-4 text-center">
          <button 
            onClick={() => handleExpandToggle(index)}
            className="text-center rounded-lg px-4 py-2 m-2 bg-[#66D9C4] text-[#343740] font-bold text-sm"
          >
            {designRole}
          </button>
          <a href={devRoleLink} target="_blank" rel="noopener noreferrer"> {/* Dynamic link for Developer role */}
            <button className="text-center rounded-lg px-4 py-2 m-2 bg-[#66D9C4] text-[#343740] font-bold text-sm">
              {devRole}
            </button>
          </a>
        </div>
      </div>
    </div>

    {/* Expandable Content Section */}
    {isExpanded && (
      <div className="p-4 bg-[#1f1f1f] text-gray-200 transition-all duration-300 ease-in-out">
        <p className="text-lg font-semibold mb-2">UI/UX Design Details</p>
        <p className="mb-4">{description}</p>
        <div className="flex flex-col gap-4">
          {images.map((src, idx) => (
            <div key={idx} className="flex flex-col items-center">
              <img
                src={src}
                alt={`Project screenshot ${idx + 1}`}
                className="rounded-md shadow-md mb-4 w-full"
              />
              <p className="text-gray-400 text-center"></p>
            </div>
          ))}
        </div>
      </div>
    )}
  </div>
);

export default Work;
