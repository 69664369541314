import React from 'react';
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { ReactComponent as Cog } from '../assets/Cog.svg';
import { ReactComponent as Cog2 } from '../assets/Cog2.svg';
import profileImg from '../assets/profile_photo.jpeg';

const About = () => {
  return (
    <div name="about" className="w-full bg-[#343740] text-gray-300 py-16 sm:py-24">
      <div className="flex flex-col justify-center items-center w-full h-full">
        
        {/* Title */}
        <div className="max-w-[1000px] w-full text-left pb-8 px-4">
          <p className="text-3xl sm:text-4xl font-rounded inline text-[#BF7973]">
            <span className="text-[#66D9C4]">&lt;</span>
            <span className="text-[#BF7973]">About </span>
            <span className="text-[#66D9C4]">/&gt;</span>
          </p>
        </div>

        {/* Content */}
        <div className="max-w-[1000px] w-full grid grid-cols-1 sm:grid-cols-2 gap-8 px-4">
          {/* Profile Image with Cogs */}
          <div className="relative flex justify-center sm:justify-start">
            {/* Profile Image */}
            <img
              src={profileImg}
              alt="Photo of Aaron Shannon"
              className="w-40 h-40 sm:w-60 sm:h-60 md:w-80 md:h-80 rounded-full object-cover z-10"
            />
            
            {/* First Small Cog */}
            <div className="absolute top-4 right-20 sm:top-6 sm:right-32 transform -translate-y-1/3 -translate-x-1/3 z-0">
              <Cog className="w-14 h-14 sm:w-20 sm:h-20" />
            </div>

            {/* Second Small Cog */}
            <div className="absolute bottom-4 left-20 sm:bottom-3 sm:right-5 transform translate-y-1/3 translate-x-1/3 z-0">
              <Cog2 className="w-20 h-20 sm:w-36 sm:h-36 animate-spin-slow" />
            </div>
          </div>

          {/* Description */}
          <div>
            <p className="text-lg sm:text-xl">
              Hi, I’m <span className="font-bold text-[#BF7973]">Aaron Shannon</span>, a passionate <span className="font-bold text-[#BF7973]">Digital Blacksmith</span> specializing in crafting intuitive and functional digital experiences.
            </p>
            <p className="text-lg sm:text-xl mt-4">
              With a strong foundation in <span className="font-bold text-[#BF7973]">UI/UX design</span> and <span className="font-bold text-[#BF7973]">software development</span>, I merge creativity and technical expertise to solve complex problems and build impactful solutions.
            </p>
            <p className="text-lg sm:text-xl mt-4">
              I’ve led and contributed to projects ranging from a <span className="font-bold text-[#BF7973]">custom search engine</span> with advanced ranking algorithms to a <span className="font-bold text-[#BF7973]">camping iOS app</span> designed to enhance outdoor adventures. My work spans backend development, front-end design, and full-stack integration, showcasing my versatility and dedication to excellence.
            </p>
            <p className="text-lg sm:text-xl mt-4">
              Let’s build something amazing together!
            </p>
          </div>
        </div>

        {/* Social Icons */}
        <div className="mt-8 flex flex-wrap justify-center items-center space-x-4 sm:space-x-6 xl:hidden">
          <a href="https://www.linkedin.com/in/aaronmshannon" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={32} className="text-[#66D9C4]" />
          </a>
          <a href="https://github.com/aashannon" target="_blank" rel="noopener noreferrer">
            <FaGithub size={32} className="text-[#66D9C4]" />
          </a>
          <a href="https://x.com/aaronmshannon" target="_blank" rel="noopener noreferrer">
            <FaTwitter size={32} className="text-[#66D9C4]" />
          </a>
          <a href="mailto:ayren.shannon@me.com?subject=Saw%20Your%20Website" target="_blank" rel="noopener noreferrer">
            <HiOutlineMail size={32} className="text-[#66D9C4]" />
          </a>
          <a href="/ShannonAaronDownloadResume.pdf" target="_blank" rel="noopener noreferrer">
            <BsFillPersonLinesFill size={32} className="text-[#66D9C4]" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
