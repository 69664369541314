import React from 'react';
import { ReactComponent as Cog } from '../assets/Cog.svg';
import { ReactComponent as Cog2 } from '../assets/Cog2.svg';
import { ReactComponent as Cog3 } from '../assets/Cog3.svg';

const Home = () => {
  return (
    <div name="home" className="relative w-full h-screen bg-[#343740] overflow-hidden mb-40 md:mb-40">
      
      {/* First Cog */}
      <div className="absolute top-1/4 right-[-10%] md:right-[-5%] transform -translate-y-1/4 z-0 opacity-100 pointer-events-none">
        <Cog className="w-40 h-40 md:w-64 md:h-64 animate-spin-slow" />
      </div>

      {/* Second, Smaller Cog */}
      <div className="absolute top-[55%] right-[-5%] md:right-[-3%] sm:top-[60%] lg:top-[63%] transform -translate-y-1/4 z-0 opacity-100 pointer-events-none">
        <Cog3 className="w-20 h-20 md:w-32 md:h-32 animate-spin-slow" />
      </div>

      {/* Third, Larger Cog */}
      <div className="absolute top-[40%] right-[-15%] md:right-[-8%] transform -translate-y-1/4 z-0 opacity-100 pointer-events-none">
        <Cog2 className="w-56 h-56 md:w-80 md:h-80 animate-spin-slow" />
      </div>

      

      {/* Main Content */}
      <div className="relative max-w-[1000px] mx-auto p-4 flex flex-col justify-center h-full z-10">
        <IntroText />
      </div>
    </div>
  );
};

// IntroText Component
const IntroText = () => (
  <div>
    <p className="text-[#66D9C4]">Hi, my name is</p>
    <h1 className="text-4xl sm:text-7xl font-rounded text-white">Aaron Shannon</h1>
    <h2 className="text-4xl sm:text-7xl font-rounded text-[#BF7973]">Full Stack Developer</h2>
    <p className="text-gray-300 py-4 max-w-[700px]">Inspired by the art of blacksmithing, I forge <span className="text-[#66D9C4] font-bold">seamless digital experiences</span> with precision, creativity, and functionality.</p>
  </div>
);

export default Home;
